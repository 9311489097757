<template>
  <b-sidebar
    id="sidebarFilter"
    v-model="isShowSidebar"
    title="FILTER"
    aria-labelledby="sidebar-no-header-title"
    no-header
    backdrop
    shadow
    right
    ref="sidebarFilter"
    @hidden="hide"
    width="80vw"
  >
    <div class="px-3 mb-3">
      <b-row class="header-filter">
        <b-col class="text-filter"> Filter </b-col>
        <b-col class="text-right">
          <button class="btn-clear" @click.prevent="clearFilter">
            x <span class="text-under">Clear Filter</span>
          </button>
        </b-col>
      </b-row>
      <div class="input-select">
        <InputSelect
          title="Transaction Date"
          name="transaction-date"
          class="mt-2"
          v-model="filter.LastDays"
          :options="dateType"
          valueField="value"
          textField="text"
        >
          <template v-slot:option-first>
            <b-form-select-option value="" disabled
              >-- Select Transaction Date --</b-form-select-option
            >
          </template>
        </InputSelect>
      </div>
      <div v-if="filter.LastDays == 0" class="mb-3">
        <InputDatePickerFilter
          textFloat="Transaciton Date From"
          name="transaction-date-from"
          ref="transaction-date-from"
          :max-datetime="filter.TransactionDateTo || new Date().toString()"
          :value="filter.TransactionDateFrom"
          @input="(val) => (filter.TransactionDateFrom = val)"
        />
      </div>
      <div v-if="filter.LastDays == 0" class="mb-3">
        <InputDatePickerFilter
          textFloat="Transaciton Date To"
          name="transaction-date-to"
          ref="transaction-date-to"
          :min-datetime="filter.TransactionDateFrom"
          :max-datetime="new Date().toString()"
          :value="filter.TransactionDateTo"
          @input="(val) => (filter.TransactionDateTo = val)"
        />
      </div>
      <b-row>
        <b-col cols="12">
          <MultiSelect
            used="tier"
            textFloat="Member tier"
            :options="options.member_tier"
            textField="memberTierName"
            valueField="id"
            placeholder="Select Member tier"
            v-model="filter.member_tiers"
            :value="filter.member_tiers"
          />
        </b-col>

        <b-col cols="12">
          <MultiSelect
            used="channel"
            textFloat="Channel"
            :options="options.channel"
            textField="channelName"
            valueField="id"
            placeholder="Select Channel"
            v-model="filter.channel_ids"
            :value="filter.channel_ids"
          />
        </b-col>
        <b-col cols="12">
          <MultiSelect
            used="branch"
            textFloat="Purchase Branch"
            :options="options.purchase_branch"
            textField="branchName"
            valueField="id"
            placeholder="Select Purchase Branch"
            v-model="filter.branch_ids"
            :value="filter.branch_ids"
          />
        </b-col>

        <b-col cols="12">
          <MultiSelect
            used="age"
            textFloat="Age Group"
            :options="options.age_group"
            textField="ageGroupName"
            valueField="id"
            placeholder="Select Age Group"
            v-model="filter.age_group_ids"
            :value="filter.age_group_ids"
          />
        </b-col>
        <b-col cols="12">
          <MultiSelect
            used="gender"
            textFloat="Gender"
            :options="options.gender"
            textField="genderGroupName"
            valueField="id"
            placeholder="Select Gender"
            v-model="filter.genders"
            :value="filter.genders"
          />
        </b-col>
        <b-col cols="12">
          <MultiSelect
            :defaultValue="true"
            v-if="isFilterLoading"
            used="product"
            textFloat="Barcode / Product"
            :options="options.product"
            textField="value"
            valueField="text"
            placeholder="Select Barcode / Product"
            v-model="filter.product"
            :value="filter.product"
          />
        </b-col>

        <b-col cols="12" class="mt-2">
          <InputDropdownCheckbox
            ref="recencys"
            textFloat="Recency"
            name="recencys"
            :valueSelectedAll="selectedAll.recencys"
            :value="filter.recencys"
            :options="options.recency"
            @onDataChangeCheckAll="(val) => (selectedAll.recencys = val)"
            @onDataChange="(val) => (filter.recencys = val)"
          />
        </b-col>

        <b-col cols="12" class="mt-2">
          <InputDropdownCheckbox
            ref="frequency"
            textFloat="Frequency"
            name="frequency"
            :valueSelectedAll="selectedAll.frequency"
            :value="filter.frequency"
            :options="options.frequency"
            @onDataChangeCheckAll="(val) => (selectedAll.frequency = val)"
            @onDataChange="(val) => (filter.frequency = val)"
          />
        </b-col>

        <b-col cols="12" class="mt-2">
          <InputDropdownCheckbox
            ref="monetary"
            textFloat="Monetary"
            name="monetary"
            :valueSelectedAll="selectedAll.monetary"
            :value="filter.monetary"
            :options="options.monetary"
            @onDataChangeCheckAll="(val) => (selectedAll.monetary = val)"
            @onDataChange="(val) => (filter.monetary = val)"
          />
        </b-col>

        <b-col cols="12">
          <InputDropdownCheckbox
            ref="repurchase"
            textFloat="Repeat Repurchase"
            name="repeat_repurchase"
            :valueSelectedAll="selectedAll.repurchase"
            :value="filter.repurchase"
            :options="options.repurchase"
            @onDataChangeCheckAll="(val) => (selectedAll.repurchase = val)"
            @onDataChange="(val) => (filter.repurchase = val)"
          />
        </b-col>
      </b-row>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Search
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import InputDropdownCheckbox from "@/components/inputs/InputDropdownCheckbox";

import Multiselect from "vue-multiselect";

export default {
  components: {
    InputDropdownCheckbox,

    Multiselect,
  },

  watch: {
    "filter.firstPurchaseDateTo"(newValue) {
      this.filter.firstPurchaseDateTo = newValue
        ? this.setEndTime(newValue)
        : null;
    },
    "filter.lastPurchaseDateTo"(newValue) {
      this.filter.lastPurchaseDateTo = newValue
        ? this.setEndTime(newValue)
        : null;
    },
    "filter.registerDateTo"(newValue) {
      this.filter.registerDateTo = newValue ? this.setEndTime(newValue) : null;
    },
    "filter.transactionDateTo"(newValue) {
      this.filter.transactionDateTo = newValue
        ? this.setEndTime(newValue)
        : null;
    },
  },
  data() {
    return {
      dateType: [
        { text: "Last 7 Days", value: 7 },
        { text: "Last 30 Days", value: 30 },
        { text: "Last 365 Days", value: 365 },
        { text: "Custom", value: 0 },
      ],
      showPopup: true,
      isShowSidebar: false,
      date: new Date().toISOString(),
      defaultData: {
        dateFrom: null,
        dateTo: null,
        minDatetime: new Date().toISOString(),
        maxDatetime: new Date().toISOString(),
      },
      selectedAll: {
        member_tiers: false,
        branch_ids: false,
        age_group_ids: false,
        channel: false,
        product: false,

        recencys: false,
        frequency: false,
        genders: false,
        monetary: false,
        repurchase: false,
      },
      selectedAllFreeze: {
        member_tiers: false,
        branch_ids: false,
        age_group_ids: false,
        channel: false,
        product: false,

        recencys: false,
        frequency: false,
        genders: false,
        monetary: false,
        repurchase: false,
      },
      filter: {
        rate_scale: 5,
        member_tiers: [],
        channel_ids: [],
        branch_ids: [],
        age_group_ids: [],
        genders: [],
        products: [],
        product: [],
        recencys: [],
        frequency: [],
        monetary: [],
        repurchase: [],
      },
      filterFreeze: {
        rate_scale: 5,
        member_tiers: [],
        channel_ids: [],
        branch_ids: [],
        age_group_ids: [],
        genders: [],
        products: [],
        product: [],
        recencys: [],
        frequency: [],
        monetary: [],
        repurchase: [],
      },
      options: {
        channel: [],
        product: [{ text: "", value: "" }],
        customerLifeCycle: [
          { text: "Min", value: 1 },
          { text: "Average Min", value: 2 },
          { text: "Average", value: 3 },
          { text: "Average Max", value: 4 },
          { text: "Max", value: 5 },
        ],
        recencys: [
          { text: "1", value: 1 },
          { text: "2", value: 2 },
          { text: "3", value: 3 },
          { text: "4", value: 4 },
          { text: "5", value: 5 },
        ],
        frequency: [
          { text: "1", value: 1 },
          { text: "2", value: 2 },
          { text: "3", value: 3 },
          { text: "4", value: 4 },
          { text: "5", value: 5 },
        ],
        monetary: [
          { text: "1", value: 1 },
          { text: "2", value: 2 },
          { text: "3", value: 3 },
          { text: "4", value: 4 },
          { text: "5", value: 5 },
        ],
      },
    };
  },
  created() {
    let now = new Date();
    let dateFrom = this.$moment(now)
      .add(-31, "day")
      .set({ hour: 0, minute: 0, second: 0 })
      .format();
    let dateTo = this.$moment(now)
      .set({ hour: 23, minute: 59, second: 59 })
      .format();
    this.defaultData.dateFrom = dateFrom;
    this.defaultData.dateTo = dateTo;
    this.defaultData.minDatetime = this.$moment(now).add(-1, "year").format();
    this.getFilterOption();

    // this.submitAction();
  },
  methods: {
    async getFilterOption() {
      const res = await this.$report(`/DashBoard/rfm_customfilter`);
      const productResponse = await this.$report.post(
        `/DashBoard/rfm_customfilter_product`,
        {
          SearchProduct: "",
          PageNumber: 1,
          RecordPerpage: 9999,
        }
      );

      let payload = res.data.detail;
      this.$nextTick(() => {
        payload.product = productResponse.data.detail.productFilterModels.map(
          (el) => {
            return { text: el.productName, value: el.id };
          }
        );
      });
      this.options = payload;
    },
    show(filter) {
      this.filter = { ...filter };
      this.isShowSidebar = true;
    },
    hide() {
      this.filter = { ...this.filterFreeze };
      this.selectedAll = { ...this.selectedAllFreeze };

      this.isShowSidebar = false;
    },
    submitAction() {
      this.filterFreeze = { ...this.filter };
      this.selectedAllFreeze = { ...this.selectedAll };
      this.$emit("filterSearch", this.filter);
      this.hide();
    },
    clearFilter() {
      this.initFilter();
    },
    initFilter() {
      this.selectedAll = {
        member_tiers: false,
        branch_ids: false,
        age_group_ids: false,
        channel: false,
        product: false,

        recencys: false,
        frequency: false,
        genders: false,
        monetary: false,
        repurchase: false,
      };
      this.filter = {
        rate_scale: 5,
        member_tiers: [],
        channel_ids: [],
        branch_ids: [],
        age_group_ids: [],
        genders: [],
        products: [],
        product: [],
        recencys: [],
        frequency: [],
        monetary: [],
        repurchase: [],
        TransactionDateFrom: "",
        TransactionDateTo: "",
        LastDays: 365,
      };
      this.isShowSidebar = false;

      this.$refs.member_tiers.clearValue();
      this.$refs.channel.clearValue();
      this.$refs.branch_ids.clearValue();
      this.$refs.age_group_ids.clearValue();
      this.$refs.genders.clearValue();
      this.$refs.recencys.clearValue();
      this.$refs.frequency.clearValue();
      this.$refs.monetary.clearValue();
      this.$refs.repurchase.clearValue();
      this.$emit("clearFilter", this.filter);
      this.$store.dispatch("clearFilter");
    },
    setEndTime(datetime) {
      return this.$moment(datetime)
        .set({ hour: 23, minute: 59, second: 59 })
        .format();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-container input {
  border: none;
}
.text-under {
  text-decoration: underline;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
}
.header-filter {
  background-color: var(--secondary-color);
  //   color: #fff;
  padding: 15px 0;
}
.btn-close {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 18px;
}
.text-filter {
  font-weight: 600;
}
::v-deep .date-picker .vdatetime-input {
  width: 100%;
  color: var(--primary-color);
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 0.5rem;
}
label {
  margin-bottom: 0.5rem;
  font-weight: 600;
}
::v-deep .b-sidebar > .b-sidebar-body {
  overflow-x: hidden;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
</style>
