<template>
  <div class="shadow-sm p-3 rounded-xl bg-white">
    <div class="chart-title content-between mb-4">
      <p class="title mb-0">
        {{ title }} <span class="sub-titile">(Average {{ average }})</span>
      </p>
      <b-button type="button" variant="export" @click="$emit('export')"
        >Export</b-button
      >
    </div>
    <div class="chart-h">
      <BarChart
        :id="id"
        :chartRange="chartRange"
        :titleLeft="titleLeft"
        :titleBottom="titleBottom"
      />
    </div>
  </div>
</template>
<script>
import BarChart from "@/components/Chart/bar.vue";

export default {
  name: "RFMChartPanel",
  components: { BarChart },
  props: {
    id: {
      required: true,
      type: [String, Number],
    },

    chartRange: {
      required: true,
      type: Object,
    },
    title: {
      required: true,
      type: String,
    },
    average: {
      required: true,
      type: [String, Number],
    },
    titleLeft: {
      required: false,
      type: String,
    },
    titleBottom: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.chart-h {
  min-height: calc(100vh / 4);
}
</style>
