<template>
  <div class="position-relative">
    <div
      class="position-relative"
      style="height: 100%; width: auto; aspect-ratio: 16/9"
    >
      <canvas :id="id"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    chartData: {
      required: true,
      type: Array,
    },
    label: {
      required: true,
      type: Array,
    },
    text: {
      require: false,
    },
    filterValue: {
      require: false,
      type: String,
    },
    filter: {
      require: false,
    },
    xText: {
      require: false,
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    addCommas(nStr) {
      var x, x1, x2;

      nStr += "";
      x = nStr.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },
    async initChart() {
      var $_ = this;
      var RepurchaseTo = "";
      var RepurchaseFrom = "";
      var PurchaseFrom = "";
      var PurchaseTo = "";
      if ($_.filterValue == "Year") {
        RepurchaseTo = $_.$moment($_.filter.RepurchaseTo).format("YYYY");
        RepurchaseFrom = $_.$moment($_.filter.RepurchaseFrom).format("YYYY");
        PurchaseFrom = $_.$moment($_.filter.PurchaseFrom).format("YYYY");
        PurchaseTo = $_.$moment($_.filter.PurchaseTo).format("YYYY");
      } else if ($_.filterValue == "Month") {
        PurchaseTo = $_.$moment($_.filter.PurchaseTo).format("MM/YYYY");
        PurchaseFrom = $_.$moment($_.filter.PurchaseFrom).format("MM/YYYY");
        RepurchaseFrom = $_.$moment($_.filter.RepurchaseFrom).format("MM/YYYY");
        RepurchaseTo = $_.$moment($_.filter.RepurchaseTo).format("MM/YYYY");
      } else {
        PurchaseTo = $_.$moment($_.filter.PurchaseTo).format("DD/MM/YYYY");
        PurchaseFrom = $_.$moment($_.filter.PurchaseFrom).format("DD/MM/YYYY");
        RepurchaseFrom = $_.$moment($_.filter.RepurchaseFrom).format(
          "DD/MM/YYYY"
        );
        RepurchaseTo = $_.$moment($_.filter.RepurchaseTo).format("DD/MM/YYYY");
      }
      const canvasBackground = {
        id: "background-canvas",
        beforeDatasetsDraw(chart, args, opt) {
          const {
            ctx,
            chartArea: { left, right, top, bottom },
            scales: { x, y },
            width,
            height,
          } = chart;

          ctx.save();
          bgColors(RepurchaseFrom, RepurchaseTo, "rgba(215,132,255,0.2)");
          bgColors(PurchaseFrom, PurchaseTo, "rgba(168,216,255,0.2)");
          function bgColors(low, high, color) {
            ctx.fillStyle = color;
            ctx.fillRect(
              chart.scales["x-axis-0"].getPixelForValue(low),
              top,
              chart.scales["x-axis-0"].getPixelForValue(high) - 59,
              bottom - 5
            );
            ctx.restore();
          }
        },
      };
      var ctx = document.getElementById(this.id);
      var that = this;
      var myChart = new Chart(ctx, {
        data: {
          labels: this.label,
          datasets: this.chartData,
        },
        options: {
          elements: {
            point: {
              radius: 0,
            },
          },
          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              title: function (tooltipItem, data) {
                return `${data.datasets[tooltipItem[0].datasetIndex].label} (${
                  data.labels[tooltipItem[0].index]
                })`;
              },
              label: function (tooltipItem, data) {
                var datasetLabel =
                  data.datasets[tooltipItem.datasetIndex].label || "Other";
                return datasetLabel + ": " + that.addCommas(tooltipItem.value);
              },
            },
          },
          hover: {
            mode: "index",
            intersect: false,
          },
          responsive: true,

          maintainAspectRatio: false,
          legend: {
            position: "bottom",
            display: true,
            labels: {
              fontSize: 12,
              boxWidth: 14,
              boxHeight: 14,
              fontFamily: "Kanit-Regular",
              fontColor: "black",
            },
          },

          interaction: {
            mode: "index",
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  fontStyle: "bold",
                  labelString: this.text,
                  fontFamily: "Kanit-Regular",
                },
                ticks: {
                  callback: function (label, index, labels) {
                    return that.addCommas(label);
                  },
                  stepSize: 5000,
                  min: 0,
                },
              },
            ],
            xAxes: [
              {
                // gridLines: {
                //   display: false,
                // },
                scaleLabel: {
                  display: true,
                  fontStyle: "bold",
                  labelString: this.xText,
                  fontFamily: "Kanit-Regular",
                },
              },
              // {
              //   type: "time",
              //   time: {
              //     unit: this.filterValue.toLowerCase() || "day",
              //     displayFormats: {
              //       day: "DD-MM-YYYY",
              //     },
              //   },
              // },
            ],
          },
        },
        plugins: [canvasBackground],
      });
    },
    destroyChart() {},
  },
};
</script>

<style lang="scss">
.legend {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
ul {
  padding-inline-start: 0px !important;
}
@media screen and (max-width: 600px) {
  .legend {
    bottom: 0;
    right: 50%;
    top: 100%;

    transform: translate(50%, -20px);
    ul {
      flex-direction: row !important;
      gap: 5px;
      li {
        margin-bottom: 0 !important;
      }
      span {
        font-size: 9px !important;
        width: max-content;
        max-width: 80px !important;
      }
    }
  }
}
</style>
