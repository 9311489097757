<template>
  <div class="bg-white
  ">
    <b-container
      ><b-row>
        <b-col
          lg="6"
          v-for="chart of dashBoardData"
          :key="chart.name"
          class="mt-2 mb-3"
        >
          <div
            class="shadow-sm p-3 rounded-xl bg-white h-100 d-flex flex-column"
          >
            <div class="chart-title">
              <div class="title">{{ chart.name }}</div>
              <b-button
                type="button"
                variant="export"
                @click="$emit('exportExcel', chart)"
                >Export</b-button
              >
            </div>
            <div class="chart-desc" v-for="desc of chart.desc" :key="desc.key">
              {{ desc.name }}
              {{ dashBoardDesc[chart.key][desc.key] }}%
            </div>
            <br />

            <LineChart
              :id="chart.name"
              :chartData="chart.data"
              class="mt-auto"
              :label="dashBoardLabel"
            />
          </div>
        </b-col> </b-row
    ></b-container>
  </div>
</template>
<script>

import LineChart from "@/components/Chart/line.vue";

export default {
  components: {
    LineChart,
  },
  props: {
    dashBoardData: { required: true, type: Array },
    dashBoardLabel: { required: true, type: Array },
    dashBoardDesc: { required: true, type: Object },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.chart-desc {
  color: black;
}
</style>
