<template>
  <div class="position-relative">
    <div class="legend" :id="'legend_' + id" :style="'--id:' + id"></div>
    <div class="position-relative chart-container">
      <canvas :id="id" height="150" width="auto"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    chartData: {
      required: true,
      type: Array,
    },
    label: {
      required: true,
      type: Array,
    },
    text: {
      require: false,
    },
    filterValue: {
      require: false,
      type: String,
    },
    xText: {
      require: false,
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    addCommas(nStr) {
      var x, x1, x2;

      nStr += "";
      x = nStr.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },
    async initChart() {
      var ctx = document.getElementById(this.id);
      var that = this;

      var myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.label,
          datasets: this.chartData,
        },
        options: {
          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              title: function (tooltipItem, data) {
                return `${data.datasets[tooltipItem[0].datasetIndex].label} (${
                  data.labels[tooltipItem[0].index]
                })`;
              },
              label: function (tooltipItem, data) {
                var datasetLabel =
                  data.datasets[tooltipItem.datasetIndex].label || "Other";
                return datasetLabel + ": " + that.addCommas(tooltipItem.value);
              },
            },
          },
          hover: {
            mode: "index",
            intersect: false,
          },
          responsive: true,
          legend: {
            display: false,
          },
          legendCallback: (chart) => {
            const legendItems = chart.data.datasets.map((ds, i) => {
              return `<li class='d-flex cursor-pointer mb-2'>
                      <span class="mr-2"
                      style="width: 12px; height: 12px; background-color:${
                        ds.backgroundColor
                      }">&nbsp;</span>
  
                    <span id="legend-label-${i}"
                      style="color: black ;font-size:10px; max-width:60px"
                    >
                      ${
                        Array.isArray(ds.label)
                          ? ds.label.join("<br/>")
                          : ds.label
                      }
                    </span>
                    </li>`;
            });
            return `
                    <ul class='d-flex flex-column justify-content-center'>
                      ${legendItems.join("")}
                    </ul>
                  `;
          },
          interaction: {
            mode: "index",
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  fontStyle: "bold",
                  labelString: this.text,
                  fontFamily: "Kanit-Regular",
                },
                ticks: {
                  callback: function (label, index, labels) {
                    return that.addCommas(label);
                  },
                  autoSkip: true,
                  min: 0,
                },
              },
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: this.xText == "" ? false : true,
                  fontStyle: "bold",
                  labelString: this.xText || "",
                  fontFamily: "Kanit-Regular",
                },
              }
              // {
              //   type: "time",
              //   time: {
              //     unit: this.filterValue.toLowerCase() || "day",
              //     displayFormats: {
              //       day: "DD-MM-YYYY",
              //     },
              //   },
              // },
            ],
          },
        },
      });

      document.getElementById("legend_" + this.id).innerHTML =
        myChart.generateLegend();
    },
    destroyChart() {},
  },
};
</script>

<style lang="scss" scoped>
.legend {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.chart-container {
  margin-right: 100px;
}
ul {
  padding-inline-start: 0px !important;
}
@media screen and (max-width: 600px) {
  .chart-container {
    margin-bottom: 20px;
    margin-right: unset;
  }
  .legend {
    bottom: 0;
    right: 50%;
    top: 100%;

    transform: translate(50%, -20px);
    ul {
      flex-direction: row !important;
      gap: 5px;
      li {
        margin-bottom: 0 !important;
      }
      span {
        font-size: 9px !important;
        width: max-content;
        max-width: 80px !important;
      }
    }
  }
}
</style>
